import React, { useState } from 'react';
import { TextField, Button, Container, Typography, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import Logo from './logo.svg';

function RegistrationForm() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        city: '',
        province: '',
        privacy_consent: false,
        marketing_consent: false
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);  // Attiva il loading

        // Controlla se i campi obbligatori sono compilati, escludendo il consenso marketing
        const { marketing_consent, ...requiredFields } = formData;
        if (Object.values(requiredFields).some(field => field === '' || field === false)) {
            alert("Per favore, completa tutti i campi obbligatori e autorizza il trattamento dei dati personali.");
            setLoading(false);  // Disattiva il loading in caso di errore
            return;
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            alert("Per favore, inserisci un indirizzo email valido.");
            setLoading(false);  // Disattiva il loading in caso di errore
            return;
        }

        try {
            const response = await fetch('https://cms2.thefirmshop.com/items/customers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            setLoading(false);  // Disattiva il loading dopo la risposta del server

            if (response.status === 204) {
                alert('Cliente aggiunto con successo!');
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    city: '',
                    province: '',
                    privacy_consent: false,
                    marketing_consent: false  // Reset del consenso marketing
                });
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error("Failed to add customer:", error);
            alert('Errore nell\'aggiunta del cliente. Controlla la console per maggiori informazioni.');
            setLoading(false);  // Assicurati di disattivare il loading in caso di errore di rete
        }
    };

    return (
        <Container maxWidth="sm">
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop: '20px' }}>
                <img src={Logo} alt="Logo" height="60" />
            </div>
            {/* <Typography variant="h4" component="h1" gutterBottom style={{ fontSize: '1.2rem' }}>
                Registrazione Cliente
            </Typography> */}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Nome"
                    variant="outlined"
                    name="first_name"
                    fullWidth
                    margin="normal"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                />
                <TextField
                    label="Cognome"
                    variant="outlined"
                    name="last_name"
                    fullWidth
                    margin="normal"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    name="email"
                    type="email"
                    fullWidth
                    margin="normal"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <TextField
                    label="Telefono"
                    variant="outlined"
                    name="phone"
                    fullWidth
                    margin="normal"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                />
                <TextField
                    label="Comune"
                    variant="outlined"
                    name="city"
                    fullWidth
                    margin="normal"
                    value={formData.city}
                    onChange={handleChange}
                    required
                />
                <TextField
                    label="Provincia"
                    variant="outlined"
                    name="province"
                    fullWidth
                    margin="normal"
                    value={formData.province}
                    onChange={handleChange}
                    required
                />
                <FormControlLabel
                    control={<Checkbox checked={formData.privacy_consent} onChange={handleChange} name="privacy_consent" color="primary" />}
                    label={<span style={{ fontSize: '0.8rem' }}>Accetto il trattamento dei miei dati personali secondo il D.LGS. 30.06.2003 N. 196 e il GDPR (UE) 2016/679 per la gestione della mia registrazione.</span>}
                />
                <FormControlLabel
                    control={<Checkbox checked={formData.marketing_consent} onChange={handleChange} name="marketing_consent" color="primary" />}
                    label={<span style={{ fontSize: '0.8rem' }}>Desidero ricevere promozioni e novità via email. Il consenso è facoltativo e revocabile in qualsiasi momento.</span>}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ padding: '10px', fontSize: '1.1rem', marginTop: 2, marginBottom: 2 }}
                    disabled={loading}  // Disabilita il bottone durante il loading
                >
                    {loading ? <CircularProgress size={24} /> : "Invia"}
                </Button>
            </form>
        </Container>
    );
}

export default RegistrationForm;
