import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { darkTheme } from './theme';
import RegistrationForm from './RegistrationForm';

function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline /> 
            <div>
                <RegistrationForm />
            </div>
        </ThemeProvider>
    );
}

export default App;
